<template>
  <div>
    <car-parking-popup-Reason
      :temp-search-reason="tempSearchReason"
      :is-popup-reason-active.sync="isPopupReasonActive"
      @select-item="fncSelectReason"
    />
    <b-modal
      ref="refModalDatePickerDateStr"
      centered
      title="วันที่เริ่มต้น"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="DateStr"
        class="mt-1"
        locale="th"
        @input="refModalDatePickerDateStr.hide()"
      />
    </b-modal>
    <b-modal
      ref="refModalDatePickerDateEnd"
      centered
      title="วันที่สิ้นสุด"
      hide-footer
      size="sm"
      modal-class="modalDatePicker"
    >
      <v-date-picker
        v-model="DateEnd"
        class="mt-1"
        locale="th"
        @input="refModalDatePickerDateEnd.hide()"
      />
    </b-modal>
    <b-modal
      ref="refModalErrorMessage"
      ok-only
      ok-variant="danger"
      ok-title="ตกลง"
      modal-class="modal-danger"
      centered
      title="กรุณาตรวจสอบข้อมูล"
    />
    <b-card
      title="ที่จอดรถ"
      class="mt-1"
    >
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="inputSearchRegNum"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                เลขทะเบียน <span class="text-danger">*</span>
              </div>
            </template>
            <b-input-group v-show="!regNum1 && !regNum2">
              <b-form-input
                id="inputSearchRegNum"
                v-model="inputSearchRegNum1"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;"
              />
              <b-form-input
                id="inputSearchRegNum2"
                ref="refInputSearchRegNum2"
                v-model="inputSearchRegNum2"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;  margin-left: 5px;"
              />
            </b-input-group>
            <b-input-group v-show="regNum1 || regNum2">
              <b-form-input
                id="inputSearchRegNum"
                v-model="regNum1"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%;"
                disabled
              />
              <b-form-input
                id="inputSearchRegNu2"
                v-model="regNum2"
                placeholder="เลขทะเบียน"
                trim
                style="display: inline;width: 40%; margin-left: 5px;"
                disabled
              />
            </b-input-group>
            <small
              v-if="validateRequireRegnum"
              class="text-danger"
            >
              กรุณากรอกทะเบียนรถและกดค้นหา
            </small>
          </b-form-group>
        </b-col>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <b-overlay
              :show="overlaySubmitButton"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                variant="primary"
                @click="fncAdd()"
              >
                ค้นหา
              </b-button>
            </b-overlay>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="พอร์ต"
            label-for="portDesc"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                พอร์ต
              </div>
            </template>
            <b-form-input
              id="InputPortDesc"
              v-model="portDesc"
              placeholder="พอร์ต"
              trim
              autocomplete="off"
              disabled
            />

          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ชื่อลูกค้า"
            label-for="cusName"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ชื่อลูกค้า
              </div>
            </template>
            <b-form-input
              id="InputCusName"
              v-model="cusName"
              placeholder="ชื่อลูกค้า"
              trim
              autocomplete="off"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1200 ? 5 : 12">
          <b-form-group
            label-for="events"
            label-cols-md="3"
            label-cols-sm="12"
            :label-align="$store.state.app.windowWidth >= 1200 ? 'right' : 'left'"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="radioChoice"
                name="testRadio"
                value="1"
                style="margin-top:10px;"
              >
                ไม่ระบุวันสิ้นสุด
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                วันที่เริ่มต้น <span class="text-danger">*</span>
              </div>
            </template>
            <div class="form-group">
              <div class="input-group">
                <b-form-input
                  id="inputDateStr"
                  :value="resolveFormatDate(DateStr)"
                  trim
                  readonly
                  placeholder="วันที่เริ่มต้น"
                  @click="fncShowDatepickerDateStr()"
                />
              </div>
            </div>
            <small
              v-if="validateRequireDateStr"
              class="text-danger"
            >
              กรุณากรอกวันที่เริ่มต้น
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                วันที่สิ้นสุด
              </div>
            </template>
            <div class="form-group">
              <div class="input-group">
                <b-form-input
                  id="inputDateEnd"
                  :value="resolveFormatDate(DateEnd)"
                  trim
                  readonly
                  placeholder="วันที่สิ้นสุด"
                  @click="fncShowDatepickerDateEnd()"
                />
              </div>
            </div>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for="reasonCode"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                สาเหตุ <span class="text-danger">*</span>
              </div>
            </template>
            <b-input-group v-show="!reasonCode">
              <b-form-input
                id="reasonCode"
                v-model="inputSearchReason"
                placeholder="ค้นหาสาเหตุ"
                trim
                @keydown.enter.native="
                  fncSearchReason(inputSearchReason)
                "
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncOpenPopupReason()"
                >
                  <feather-icon icon="ListIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-show="reasonCode">
              <b-form-input
                id="reasonCode"
                :value="reasonName"
                trim
                disabled
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="fncCancelReason()"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="validateRequireReason"
              class="text-danger"
            >
              กรุณากรอกสาเหตุ
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="หมายเหตุ"
            label-for="reMark"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                หมายเหตุ <span class="text-danger">*</span>
              </div>
            </template>
            <b-form-input
              id="InputReMark"
              v-model="reMark"
              placeholder="หมายเหตุ"
              trim
              autocomplete="off"
            />
            <small
              v-if="validateRequireReMark"
              class="text-danger"
            >
              กรุณากรอกหมายเหตุ
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- <b-row class="delete-margin">
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label="ผู้ขอทำรายการ"
            label-for="orderBy"
            label-cols-md="3"
            label-cols-sm="12"
          >
            <template v-slot:label>
              <div style="margin-top:10px;">
                ผู้ขอทำรายการ <span class="text-danger">*</span>
              </div>
            </template>
            <b-form-input
              id="InputOrderBy"
              v-model="orderBy"
              placeholder="ผู้ขอทำรายการ"
              trim
              autocomplete="off"
              :disabled="errorMessage === 'ERROR' ? true : false"
            />
            <small
              v-if="validateRequireOrderBy"
              class="text-danger"
            >
              กรุณากรอกผู้ขอทำรายการ
            </small>
          </b-form-group>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col :cols="$store.state.app.windowWidth >= 1600 ? 6 : 12">
          <b-form-group
            label-for=""
            label-cols-md="3"
            label-cols-sm="12"
          >
            <b-overlay
              :show="overlaySubmitButton"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="primary"
              class="d-inline-block"
            >
              <b-button
                variant="primary"
                :disabled="errorMessage === 'ERROR' ? true : false"
                @click="fncSubmit()"
              >
                Submit
              </b-button>
            </b-overlay>
            <b-button
              class="ml-1"
              variant="outline-warning"
              @click="fncResetForm()"
            >
              แก้ไขข้อมูล
            </b-button>
            <b-button
              class="ml-1"
              variant="outline-secondary"
              :to="{ name: 'car-parking' }"
            >
              ยกเลิก
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BInputGroup, BFormInput, BButton, BOverlay, BFormRadio, BInputGroupAppend,
} from 'bootstrap-vue'
import {
  ref, computed,
} from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import { useToast } from 'vue-toastification/composition'
import {
  VDatePicker,
} from 'vuetify/lib'
import router from '@/router'
import CarParkingPopupReason from './car-parking-popup/CarParkingPopupReason.vue'
import CarParkingStoreModule from './CarParkingStoreModule'
// eslint-disable-next-line import/order
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BOverlay,
    BFormRadio,
    BInputGroupAppend,
    VDatePicker,
    CarParkingPopupReason,
  },

  setup() {
    const APP_STORE_MODULE_NAME = 'appCarParking'
    const toast = useToast()

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, CarParkingStoreModule)

    // ประกาศตัวแปร Textbox
    const inputSearchRegNum1 = ref(null)
    const inputSearchRegNum2 = ref(null)
    const inputSearchReason = ref(null)

    // ประกาศตัวแปร radio
    const radioChoice = ref(null)

    // ประกาศตัวแปรสำหรับเก็บค่า
    const username = ref(null)
    const cpncod = ref(null)
    const branchCode = ref(null)
    const branchName = ref(null)
    const reasonCode = ref(null)
    const reasonName = ref(null)
    const regNum1 = ref(null)
    const regNum2 = ref(null)
    const portDesc = ref(null)
    const cusName = ref(null)
    const tempSearchRegNum = ref(null)
    const tempSearchReason = ref(null)
    const dataResult = ref([])
    const isDefaultBranchFromLogin = ref(false)
    const DateStr = ref(null)
    const DateEnd = ref(null)
    const reMark = ref(null)
    const orderBy = ref(null)
    const errorMessage = ref(null)

    // ประกาศตัวแปร Active / Inactive
    const isPopupReasonActive = ref(false)

    // ประกาศตัวแปรแสดงสถานะ Loading
    const overlaySubmitButton = ref(false)
    const refModalErrorMessage = ref(null)

    /* ฟังก์ชั่นเมื่อมีการกดเลือกเลขทะเบียนจาก Popup */
    // const fncSelectRegNum = data => {
    //   regNum1.value = data.regnum1
    //   regNum2.value = data.regnum2
    //   portDesc.value = data.cpnbrncod_desc
    //   cusName.value = data.customer
    // }

    // ประกาศตัวแปร Ref
    const refModalDatePickerDateStr = ref(null)
    const refModalDatePickerDateEnd = ref(null)

    /* ฟังก์ชั่นแสดง DatePicker วันที่ข้อมูล */
    const fncShowDatepickerDateStr = () => {
      refModalDatePickerDateStr.value.show()
    }

    /* ฟังก์ชั่นแสดง DatePicker วันที่ข้อมูล */
    const fncShowDatepickerDateEnd = () => {
      refModalDatePickerDateEnd.value.show()
    }

    /* ฟังก์ชั่นเปิด Popup พอร์ต */
    const fncOpenPopupReason = search => {
      if (search) tempSearchReason.value = search
      else tempSearchReason.value = null

      isPopupReasonActive.value = true
    }

    /* ฟังก์ชั่นค้นหาพอร์ต */
    const fncSearchReason = val => {
      if (val) {
        const payload = {
          action: 'LIST',
          events: '',
          function: 'GET',
          recactcod: null,
          recstscod: null,
          usrcod: null,
          tabkeyone: 'PARKING_REASON',
          tabkeytwo: val,
        }

        store
          .dispatch(`${APP_STORE_MODULE_NAME}/SP_CONFIG`, payload)
          .then(response => {
            const getSearchResult = response.data.responseData.filter(
              item => item.tabdsctha.toLowerCase().indexOf(val) > -1 || item.tabkeytwo.toLowerCase().indexOf(val) > -1,
            )

            if (getSearchResult.length === 1) {
              branchCode.value = getSearchResult[0].tabkeytwo
              branchName.value = getSearchResult[0].tabdsctha
            } else if ((getSearchResult.length > 1)) {
              fncOpenPopupReason(val)
            }
          })
      }
    }

    /* ฟังก์ชั่นยกเลิกพอร์ต */
    const fncCancelReason = () => {
      reasonCode.value = null
      reasonName.value = null
      inputSearchReason.value = null
    }

    /* ฟังก์ชั่นเมื่อมีการกดเลือกพอร์ตจาก Popup */
    const fncSelectReason = data => {
      reasonCode.value = data.tabkeytwo
      reasonName.value = data.tabdsctha
    }

    const fncAdd = () => {
      overlaySubmitButton.value = true
      setInterval(() => { overlaySubmitButton.value = false }, 5000)
      const payloadadd = {
        action: 'VIEW',
        events: 'CUSTOMER',
        function: 'GET',
        USERNAME: username.value ? username.value : JSON.parse(localStorage.getItem('userData')).member_no,
        REGNUM1_IN: inputSearchRegNum1.value,
        REGNUM2_IN: inputSearchRegNum2.value,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CAR_PARKING`, payloadadd)
        .then(response => {
          console.log(payloadadd)
          console.log(response.data.responseData[0].success, 'successADD')
          portDesc.value = response.data.responseData[0].cpnbrncod_desc
          cusName.value = response.data.responseData[0].customer_name
          if (response.data.responseData[0].success === 'ERROR') {
            errorMessage.value = response.data.responseData[0].success
            dataResult.value = response.data.responseData
            console.log(response.data.responseData, 'dataADD')
            toast({
              component: ToastificationContent,
              props: {
                title: 'แจ้งเตือน',
                icon: 'CheckCircleIcon',
                variant: 'danger',
                text: 'ไม่พบข้อมูล',
              },
            })
          }
        })
    }
    // ประกาศตัวแปรอื่นๆ
    const onSubmitStatus = ref(false)

    const validateRequireRegnum = computed(() => {
      if (!inputSearchRegNum1.value && !inputSearchRegNum2.value && onSubmitStatus.value) return true
      return false
    })

    const validateRequireDateStr = computed(() => {
      if (!DateStr.value && onSubmitStatus.value) return true
      return false
    })

    const validateRequireReason = computed(() => {
      if (!reasonName.value && onSubmitStatus.value) return true
      return false
    })

    const validateRequireReMark = computed(() => {
      if (!reMark.value && onSubmitStatus.value) return true
      return false
    })

    const validateRequireOrderBy = computed(() => {
      if (!orderBy.value && onSubmitStatus.value) return true
      return false
    })

    /* ฟังก์ชั่นเมื่อกดปุ่มยืนยัน */
    // eslint-disable-next-line consistent-return
    const fncSubmit = () => {
      onSubmitStatus.value = true
      if (validateRequireRegnum.value) return false
      if (validateRequireDateStr.value) return false
      if (validateRequireReason.value) return false
      if (validateRequireReMark.value) return false
      onSubmitStatus.value = false

      overlaySubmitButton.value = true
      setInterval(() => { overlaySubmitButton.value = false }, 5000)
      const payload = {
        action: 'ADD',
        events: 'PARKING',
        function: 'SUBMIT',
        USERNAME: username.value ? username.value : JSON.parse(localStorage.getItem('userData')).member_no,
        REGNUM1_IN: inputSearchRegNum1.value,
        REGNUM2_IN: inputSearchRegNum2.value,
        PARK_DATE_STR_IN: DateStr.value,
        PARK_DATE_END_IN: DateEnd.value,
        PARKING_REASON_IN: reasonName.value,
        REMARK_IN: reMark.value,
      }

      store
        .dispatch(`${APP_STORE_MODULE_NAME}/SP_RT_UPD_CAR_PARKING`, payload)
        .then(() => {
          console.log(payload, 'addsubmit')
          toast({
            component: ToastificationContent,
            props: {
              title: 'แจ้งเตือน',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'บันทึกข้อมูลสำเร็จ',
            },
          })
          router.push('/car_parking') // คำสั่ง Redirect
        })
    }

    /* ฟังก์ชั่นเมื่อกดปุ่มยกเลิก จะทำการ reset ข้อมูล */
    const fncResetForm = () => {
      inputSearchRegNum1.value = null
      inputSearchRegNum2.value = null

      branchCode.value = null
      branchName.value = null
      portDesc.value = null
      cusName.value = null
      regNum1.value = null
      regNum2.value = null
      tempSearchRegNum.value = null
      radioChoice.value = null
      DateStr.value = null
      DateEnd.value = null
      reMark.value = null
      orderBy.value = null
      errorMessage.value = null
      reasonName.value = null
      validateRequireRegnum.value = null
      validateRequireDateStr.value = null
      validateRequireReason.value = null
      validateRequireReMark.value = null
      validateRequireOrderBy.value = null
    }
    /* ========== ส่วนโค้ดการทำงานฟังก์ชั่น ========== */
    /* กำหนดปีเป็น พ.ศ. */
    const toBuddhistYear = (val, format) => {
      const christianYear = val.format('YYYY')
      // eslint-disable-next-line radix
      const buddhishYear = (parseInt(christianYear) + 543).toString()
      return val
        .format(
          format
            .replace('YYYY', buddhishYear)
            .replace('YY', buddhishYear.substring(2, 4)),
        )
        .replace(christianYear, buddhishYear)
    }

    /* กำหนดรูปแบบ ว/ด/ป */
    const resolveFormatDate = val => (val
      ? toBuddhistYear(moment(val), 'DD/MM/YYYY')
      : null)
    const paginateArray = (array, getPerPage, page) => array.slice((page - 1) * getPerPage, page * getPerPage)
    /* ========== ส่วนการทำงานแสดงผล UI ========== */

    return {

      // ประกาศตัวแปร Ref
      radioChoice,
      paginateArray,

      // ประกาศตัวแปร Textbox
      refModalDatePickerDateStr,
      fncShowDatepickerDateStr,
      refModalDatePickerDateEnd,
      fncShowDatepickerDateEnd,
      resolveFormatDate,
      DateStr,
      DateEnd,
      inputSearchRegNum1,
      inputSearchRegNum2,
      inputSearchReason,
      reMark,
      orderBy,
      errorMessage,

      // ประกาศตัวแปรสำหรับเก็บค่า
      username,
      cpncod,
      branchCode,
      branchName,
      reasonCode,
      reasonName,
      regNum1,
      regNum2,
      portDesc,
      cusName,
      tempSearchRegNum,
      tempSearchReason,
      dataResult,
      isDefaultBranchFromLogin,
      // ประกาศตัวแปร Active / Inactive
      isPopupReasonActive,

      // ประกาศตัวแปรแสดงสถานะ Loading
      overlaySubmitButton,
      refModalErrorMessage,

      // ส่วนโค้ดการทำงานฟังก์ชั่น
      fncAdd,
      fncSearchReason,
      fncCancelReason,
      fncSelectReason,
      fncOpenPopupReason,
      fncSubmit,
      fncResetForm,

      validateRequireRegnum,
      validateRequireDateStr,
      validateRequireReason,
      validateRequireReMark,
      validateRequireOrderBy,
    }
  },
}
</script>

<style>
.v-date-picker-title {
    color: black;
}

.v-picker__title.primary {
  margin-top: -80px;
}

.v-date-picker-table.v-date-picker-table--date.theme--light {
  margin-bottom: -30px;
}

.v-picker.v-card.v-picker--date.theme--light {
  margin-left: 0px;
}

button.v-btn.v-btn--active.v-btn--rounded.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-date-picker-table__current.v-btn--active.v-btn--text.theme--light.accent {
  color: blue;
}

button.v-btn.v-size--default.v-btn--active.theme--light.accent {
  color: blue;
}

.v-picker__title.primary {
  display: none;
}

.modalDatePicker .modal-body {
  padding: 0px;
}

.modalDatePicker .modal-content {
  width: 280px;
}

.v-picker__body.theme--light {
  width: 280px !important;
}

body.modal-open {
  height: 100vh;
  overflow-y: hidden;
}

/* for local only
.delete-margin {
  margin-bottom: -60px !important;
} */
</style>
